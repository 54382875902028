.profile .title {
  padding: 8px 0;
  font-size: 18px;
  letter-spacing: .5px;
  color: hsl(0, 0%, 20%);
  text-shadow: none;
}

.profile .font-sans-serif { font-family: sans-serif; }

.profile .flex-basis-35 { flex: 0 0 37% }
.profile .flex-basis-60 { flex: 0 0 60% }
.profile .flex-basis-100 { flex: 0 0 100% }
.profile .flex-wrap { flex-wrap: wrap }

.profile .text-center { text-align: center; }

.profile .stack-s { margin-bottom: 4px; }
.profile .stack-m { margin-bottom: 8px; }
.profile .stack-l { margin-bottom: 16px; }
.profile .stack-xl { margin-bottom: 32px; }

.profile .inline-s { margin-right: 4px; }
.profile .inline-m { margin-right: 8px; }
.profile .inline-l { margin-right: 16px; }
.profile .inline-xl { margin-right: 32px; }

.profile .inset-s { padding: 4px; }
.profile .inset-m { padding: 8px; }
.profile .inset-l { padding: 16px; }
.profile .inset-xl { padding: 32px; }

.profile .color-grey { color: hsl(0, 0%, 30%); }
.profile .color-dark-grey { color: hsl(0, 0%, 20%); }
.profile .color-light-grey { color: hsl(0, 0%, 50%); }

.profile .bg-gradient-blue { background: linear-gradient(162deg, hsl(0, 0%, 100%) 50%, hsl(199, 98%, 97%) 100%); }

.profile .fill-grey { fill: hsl(0, 0%, 30%); }
.profile .fill-light-grey { fill: hsl(0, 0%, 50%); }

.profile .size-20 {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.profile .break-inside-avoid { page-break-inside: avoid;}

.profile .link,
.profile .link:hover,
.profile .link:visited {
  color: hsl(0, 0%, 30%);
  text-decoration: none;
}
