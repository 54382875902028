.page {
  height: 100%;
  border-left: 16px solid var(--highlight);
  overflow-y: auto;
  background: linear-gradient(-22deg, hsl(0, 0%, 95%), hsl(199, 98%, 97%));
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.page::-webkit-scrollbar { display: none; }

.page__cover {
  width: calc(100% + 16px);
  flex: 1 0 auto;
}

@media (min-width: 820px) {

  .page {
    padding: 76px 0 0 118px;
    min-height: 100%;
  }

  .page__cover {
    margin-left: -118px;
    width: calc(100% + 118px);
    height: 100%;
  }

  .page .mobile\:d-none { display: initial; }

}
