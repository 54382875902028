.modal {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  background: transparent;
}
