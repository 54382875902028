


/* $focus: hsl(199, 98%, 97%); */

.button {
  display: inline-block;
  padding: 12px 32px;
  font-size: 16px;
  border-radius: 24px;
  border-width: 2px;
  border-color: hsla(0, 0%, 0%, 0);
  border-style: solid;
  font-weight: bold;
  font-family: sans-serif;
  text-decoration: none;
  white-space: nowrap;
  line-height: 1;
  background-color: hsla(0, 0%, 0%, 0);
  transition: background .5s, color .5s;
}

.button:hover { cursor: pointer; }
.button:focus { outline: none; }

.button--affirmative {
  box-shadow: 0 3px 6px hsla(0, 0%, 0%, .16);
}

.button--affirmative:hover { box-shadow: 0 4px 7px hsla(0, 0%, 0%, .20); }
.button--affirmative:focus { border-color: hsl(199, 60%, 60%); }

.button--affirmative.button--light {
  color: hsl(0, 0%, 95%);
  background: hsl(199, 98%, 10%);
}

.button--affirmative.button--light:hover { background: darken(hsl(199, 98%, 10%), 5%) }

.button--affirmative.button--dark {
  color: hsl(199, 98%, 10%);
  background: hsl(0, 0%, 95%);
}

.button--affirmative.button--dark:hover {
  color: darken(hsl(199, 98%, 10%), 15%);
  background: darken(hsl(0, 0%, 95%), 5%);
}

.button--affirmative.button--dark:active {
  color: darken(hsl(199, 98%, 10%), 20%);
  background: darken(hsl(0, 0%, 95%), 10%);
}

.button--link.button--light { color: hsl(199, 98%, 10%); }
.button--link.button--dark { color: hsl(0, 0%, 95%); }
