.profile {
  /* background: linear-gradient(321deg, hsl(15, 100%, 99%) 35%, hsl(200, 100%, 99%) 100%); */
  background: hsl(0, 0%, 95%);
  height: 100%;
  padding: 16px;
  overflow-x: auto;
  overflow-y: auto;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    "sidebar"
    "actions"
    "content"
}

.profile__sidebar {
  grid-area: sidebar;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 24px;
  padding-top: 32px;
}

.profile_image {
  width: 50%;
  max-width: 200px;
  height: auto;
  margin: 0 auto;
}
.profile_image > img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.profile__sidebar::-webkit-scrollbar {
  display: none;
}

.profile__actions {
  grid-area: actions;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* padding: 4px; */
}

.profile__actions::-webkit-scrollbar {
  display: none;
}

.profile__action-icon {
  fill: hsl(0, 0%, 50%);
  cursor: pointer;
}

.profile__action-icon:hover,
.profile__action-icon--active {
  fill: hsl(0, 0%, 20%);
}

.profile__content {
  width: 100%;
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 16px;
}

.profile__content-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.profile__content-wrapper::-webkit-scrollbar {
  display: none;
}

.profile--center .profile__content {
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: 800px;
}

.profile .active {
  color: hsl(0, 0%, 20%);
  background-color: hsla(0, 0%, 90%, 1);
}

.propfile .mobile\:d-none { display: none; }
.profile .desktop\:d-none { display: initial; }

.profile__sidebar,
.profile__actions,
.profile__content > div {
  transition-property: opacity, transform;
  transition-duration: 1s, 1s;
  transition-timing-function: ease-in-out;
  transform: translateZ(0);
}

.profile__content > div:first-child { transition-delay: .5s; }
.profile__content > div:nth-child(2) { transition-delay: .65s; }
.profile__content > div:last-child { transition-delay: .8s; }
.profile__actions { transition-delay: 1s; }

.profile--loading .profile__sidebar,
.profile--loading .profile__actions,
.profile--loading .profile__content > div { opacity: 0; }

.kronofogden--hack {
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1.6;
}

.profile__contact-desktop {
  width: 100%;
  height: 100%;
  background: linear-gradient(321deg, hsl(15, 100%, 95%) 35%, hsl(200, 100%, 95%) 100%);
}

.mobile\:d-none { display: none; }

@media (min-width: 1025px) {
  .profile {
    padding: 24px;
    grid-gap: 16px;
    grid-template-columns: 350px 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "sidebar actions"
      "sidebar content"
  }

  .profile__actions { padding: 0; }

  .profile .desktop\:d-none { display: none; }
  .propfile .mobile\:d-none { display: initial }

  .profile_image { margin: 0 auto; }

  .mobile\:d-none { display: initial; }


}

.profile__intro-name {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media print {

  .profile {
    display: block;
    height: auto;
    background: transparent;
  }

  .profile_image {
    margin: 0;
    margin-right: 24px;
  }

  .profile__sidebar {
    box-shadow: none;
  }

  .profile__intro {
    display: flex;
    flex-wrap: wrap;
  }

  .profile__intro-name {
    justify-content: flex-start;
  }

  .profile__intro-text {
    flex-basis: 48%;
    padding-left: 24px;
  }

  .profile__content {
    width: 100%;
    height: auto;
    grid-area: content;
    display: block;
  }

  .profile__actions {
    display: none;
  }

  .print\:d-none { display: none; }
}
