.profile .button {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-family: sans-serif;
  border-radius: 16px;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 24px;
  line-height: 1;
  font-weight: bold;
  border-color: transparent;
  border-width: 2px;
  color: hsl(0, 0%, 50%);
  fill: hsl(0, 0%, 50%);
  outline: none;
  box-shadow: none;
  border-style: solid;
  transition-property: background-color, color, fill;
  transition-duration: .3s, .3s, .3s;
}

.profile .button:focus {
  border-color: hsl(199, 98%, 43%);
}

.profile .button--link.button--disabled:hover,
.profile .button--link {
  background-color: hsla(0, 0%, 94%, 0);
  color: hsl(0, 0%, 50%);
  fill: hsl(0, 0%, 50%);
}

.profile .button--link:hover {
  background-color: hsla(0, 0%, 94%, 1);
}

.profile .button--link.button--disabled {
  cursor: not-allowed;
}

.profile .button--neutral {
  background-color: hsl(0, 0%, 94%);
}

.profile .button--neutral:hover {
  background-color: hsl(0, 0%, 90%);
}

.profile .button--link:focus,
.profile .button--link:hover,
.profile .button--neutral:focus,
.profile .button--neutral:hover {
  color: hsl(0, 0%, 20%);
  fill: hsl(0, 0%, 20%);
}

.profile .button--danger:hover {
  color: white;
  background-color: hsl(0, 89%, 43%);
}

.profile .button--affirmative {
  border-color: hsla(0, 0%, 0%, 0);
  color: hsl(0, 0%, 98%);
  background: hsl(167, 89%, 43%);
}

.profile .button--affirmative:hover {
  background: hsl(167, 89%, 38%);
}
