
:root {
  --transparent: hsla(0, 0%, 0%, 0);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 5%);
  --highlight: hsl(199, 98%, 10%);
  --grey40: hsl(0, 0%, 40%);
  --grey95: hsl(0, 0%, 95%);

  --font-sans-serif: 'Roboto', sans-serif;

  --space-s: 8px;
  --space-m: 16px;
  --space-l: 32px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.p-fixed { position: fixed; }
.p-relative { position: relative; }
.p-absolute { position: absolute; }

.top-0 { top: 0; }
.bottom-0 { bottom: 0; }
.left-0 { left: 0; }
.right-0 { right: 0; }

.h-100 { height: 100%; }
.w-100 { width: 100%; }
.vw-100 { width: 100vw; }
.vh-100 { height: 100vh; }
.vh-90 { height: 90vh; }
.vh-7 { height: 7vh; }
.h-100 { height: 100%; }
.pointer { cursor: pointer; }

.list-style-none { list-style: none; }

.d-block { display: block; }
.d-inline-block { display: inline-block; }
.d-none { display: none; }
.d-flex { display: flex; }
.d-inline-flex { display: inline-flex; }

.flex-column { flex-direction: column; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.justify-between { justify-content: space-between; }
.align-center { align-items: center; }
.align-start { align-items: flex-start; }
.flex-fill { flex: 1 1; }
.flex-wrap { flex-wrap: wrap; }

/*
@each $space-name, $space-value in $spacing {
  .inset-#{$space-name} { padding: $space-value; }
  .stack-#{$space-name} { margin-bottom: $space-value; }
  .inline-#{$space-name} { margin-right: $space-value; }
}
*/

.inset-xl { padding: 40px; }

.stack-xl { margin-bottom: 64px; }
.stack-xxl { margin-bottom: 128px; }

.inline-xl { margin-right: 64px; }
.inline-xxl { margin-right: 128px; }

.inset-stretch-l { padding: 16px 32px; }
.inset-stretch-xl { padding: 32px 80px; }

/*
@each $font-variant, $font-family in $font-families {
  .font-#{$font-variant} { font-family: $font-family; }
}
*/

/*
@each $color-name, $color-value in $colors {
  .bg-#{$color-name} { background-color: $color-value; }
  .color-#{$color-name} { color: $color-value; }
  .hover\:color-#{$color-name}:hover { color: $color-value; }
  .border-#{$color-name} { border: 1px solid $color-value; }
  .border-color-#{$color-name} { border-color: $color-value !important; }
}
*/

.border-highlight { border: 1px solid var(--highlight); }
.border-left-highlight { border-left: 1px solid var(--highlight); }
.border-bottom-highlight { border-bottom: 1px solid var(--highlight); }
.border-xl { border-width: 16px; }
.round-s { border-radius: 8px; }
.round-m { border-radius: 16px; }
.round-l { border-radius: 23px; }

.box-shadow { box-shadow: 0 3px 6px hsla(0, 0%, 0%, .16); }
.hover\:box-shadow-l:hover { box-shadow: 0 4px 7px hsla(0, 0%, 0%, .20); }

.text-shadow { text-shadow: 0 3px 6px hsla(0, 0%, 0%, .16); }
.text-center { text-align: center; }
.text-decoration-none { text-decoration: none; }
.uppercase { text-transform: uppercase; }
.bold { font-weight: 500; }
.slim { font-weight: 300; }

.scroll { overflow-y: auto; }
.overflow-hidden { overflow: hidden; }

.scrollbar-hidden::-webkit-scrollbar { display: none; }
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.invisible {
  visibility: hidden;
}

.select-none {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.pointer-events-none { pointer-events: none; }
.pointer-events-auto { pointer-events: auto; }
