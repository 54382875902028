
.title {
  font-family: sans-serif;
  font-weight: bold;
  color: var(--highlight);
  text-shadow: 0 3px 6px hsla(0, 0%, 0%, .16);
}

.title--1 { font-size: min(64px, max(3.35vw, 32px)); }
.title--2 { font-size: min(32px, max(3.35vw, 18px)); }
