.navigation-social {
  line-height: 0;
}
.navigation-social svg {
  margin-right: var(--space-l);
  height: 20px;
  width: auto;
  fill: var(--color-off-black);
}

.navigation-social a {
  position: relative;
  transition: top .3s;
  top: 0px;
}
.navigation-social a:hover {
  top: -3px;
}

.navigation-social  svg .inner {
  fill: var(--color-off-white);
}
.navigation-social  > :last-child {
  margin-right: 0px;
}
