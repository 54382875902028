.experience__title {
  color: hsl(167, 89%, 43%);
  font-family: sans-serif;
}

.experience__sub-title {
  font-family: sans-serif;
  color: hsl(0, 0%, 30%);
  font-weight: bold;
}

.experience__date {
  font-family: sans-serif;
  color: hsl(0, 0%, 30%);
}

.experience__description {
  font-family: sans-serif;
  color: hsl(0, 0%, 30%);
}

.experience__link {
  text-decoration: none;
  font-family: sans-serif;
  color: hsl(0, 0%, 40%);
  fill: hsl(0, 0%, 40%);
  display: inline-flex;
  align-items: center;
}

.experience__link:hover,
.experience__link:hover.experience__link:visited {
  cursor: pointer;
  color: hsl(0, 0%, 20%);
  fill: hsl(0, 0%, 20%);
}

.experience__link:visited { color: hsl(0, 0%, 40%); }

.experience__link-icon {
  margin-right: 8px;
  transform: translateY(-1px);
}

@media print {
  .experience {
    box-shadow: none;
    margin-bottom: 8px !important;
  }
}
