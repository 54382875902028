.header {
  width: 100%;
  display: none;
  overflow-x: auto;
  position: fixed;
  top: 0;
  border-left: 16px solid var(--highlight);
  white-space: nowrap;
  transition: padding .5s;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.header::-webkit-scrollbar { display: none; }

.header--light {
  background: hsl(0, 0%, 100%);
}

.header--dark {
  background: var(--highlight);
}

.header__logo {
  position: absolute;
  left: 48px;
  z-index: 1;
}

.header .mobile\:d-none { display: none; }

@media (min-width: 820px) {
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 16px 32px;
    z-index: 400;
    position: fixed;
    top: 0;
    width: 100%;
    pointer-events: none;
    overflow-x: visible;
    overflow-y: visible;
  }

  .header .mobile\:d-none { display: initial; }

  .header--dark {
    padding: 32px; //Todo: make a --large theme
  }
}
