
.home {
  padding: 16px 16px 32px;
  flex: 0 0 auto;
}

.services {
  width: 100%;
  flex: 0 0 auto;
}

.services .mobile\:d-none { display: none; }

.services__wrapper {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  display: flex;
  align-items: stretch;
}

.services__wrapper::-webkit-scrollbar { display: none; }

.services__service {
  border: 1px solid hsl(199, 20%, 90%);
  white-space: normal;
  flex: 0 0 80%;
  margin: 8px 16px 16px;
  padding: 16px;
  border-radius: 8px;
  background: hsl(199, 20%, 98%);
  box-shadow: 1px 2px 8px hsla(0, 0%, 10%, .2);
}

.services__service-icon {
  margin-right: 32px;
  margin-top: 3px;
}

.contact {
  padding: 24px 16px;
  height: 100%;
}


@media (min-width: 820px) {

  .content {
    padding: 76px 0 0 118px;
  }
  
  .content .mobile\:d-none { display: initial; }

  .home {
    min-height: 70%;
    flex: 0 0 auto;
  }

  .services {
    padding: 64px 48px;
  }

  .services__wrapper {
    justify-content: space-around;
    display: block;
  }

  .services__service {
    background: hsla(0, 0%, 0%, 0);
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    display: flex;
    margin-bottom: 32px;
    border: none;
  }

}
