.logo {
  width:80px;
  height: 80px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 45px;
  letter-spacing: -6px;
  font-weight: 600;
  font-family: 'Robot', sans-serif;
  overflow: hidden;
  line-height: 1;
  transition: all .5s;
}

.logo span {
  margin-left: 0px;
  margin-top: 0px;
  display: block;
}

.logo--light {
  color: hsl(0, 0%, 95%);
  background: hsl(199, 98%, 10%);
}

.logo--dark {
  color: hsl(199, 98%, 10%);
  background: hsl(0, 0%, 95%);
}
