.tags {
  font-family: sans-serif;
  list-style: none;
  font-size: 12px;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.tag {
  display: inline-block;
  padding: 4px 8px;
  background: hsl(0, 0%, 95%);
  border: 1px solid hsl(0, 0%, 90%);
  margin: 2px;
  border-radius: 4px;
  font-size: 12px;
}

.tag--clickable:hover {
  background: hsl(0, 0%, 90%);
  cursor: pointer;
}

.tag--active {
  border-color: hsl(199, 98%, 43%);
  position: relative;
}

.tag--active::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(199, 98%, 43%, .2);
}
